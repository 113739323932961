import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../style/index.scss"
import PrenotaOra from "../components/prenotaOra"

const Servizi = ({ data }) => {
  return (
    <Layout page="su di me">
      <Seo
        title="Su di me"
        description="Consulenza psicologica, Terapia di coppia, Valutazione psicodiagnostica, Psicoterapia sensomotoria, EMDR - guarire dal trauma, Psicoterapia e caos, Psicoterapia cognitivo-comportamentale, Percorso breve per la riduzione dell'ansia"
      />
      <article id="su-di-me">
        <h1>Chi Sono</h1>

        <div className="service-block">
          <Img className="normal-size pic" fluid={data.photo13.childImageSharp.fluid} loading="eager" />
        </div>

        <div className="service-block with-pic">
          <div>
            <h4>Qualche informazione su di me…</h4>
            <p>
              Penso che sia importante che tu sappia qualcosa in pi&ugrave; su di me prima di iniziare a lavorare insieme.
            </p>
          </div>
          <div className="pic-wrapper">
            <Img className="normal-size pic" fluid={data.heroPic.childImageSharp.fluid} loading="eager" />
          </div>
        </div>

        <div className="service-block">
          <div>
            <p>
              Fin da ragazzino mi sono avvicinato ed appassionato al mondo della danza, trovando in essa una forma di espressione e di comunicazione senza eguali, qualcosa che mi ha aiutato ad esprimermi pienamente e sinceramente. Crescendo a contatto con la danza, ho imparato ad aver cura dei dettagli in quello che faccio e a gestire il complesso di intense emozioni che caratterizzano quel mondo, dallo stress per il duro lavoro fra studio e preparazione, all'ansia da prestazione al momento dell'esibizione. Le prime volte che mi sono trovato a calcare il palcoscenico non &egrave; stato facile stare dietro le quinte, aspettare il mio turno in un turbinio di emozioni forti, alcune bellissime ma altre difficili da gestire. Inoltre per entrare in scena &egrave; necessario essere sicuri al 100% di ci&ograve; che si sta facendo, avere confidenza col proprio corpo e, soprattutto, essere perfettamente sintonizzati col messaggio che si vuole comunicare al pubblico.
            </p>
            <p>
              Col tempo ho imparato a fare amicizia con le mie emozioni e a conoscerle nel massimo della loro espressione, in quel breve ed intenso momento dietro le quinte e poi sotto il calore dei riflettori; questo mi ha permesso di conoscere davvero me stesso, chi sono e come funziona la mia vita emotiva, una ricchezza per me preziosissima anche fuori dal palcoscenico. Ho coltivato e messo a frutto questa risorsa tramite lo studio universitario della psicologia clinica e la specializzazione post lauream in psicoterapia e oggi, nel mio lavoro di terapeuta a Milano, metto tutto ci&ograve; al servizio dei miei pazienti. Nel mio percorso di formazione ho portato avanti in parallelo l'universit&agrave; e lo studio della danza, nel ruolo di assistente coreografo e poi insegnante di modern-jazz e contemporaneo.
            </p>
            <p>
            Questo mio doppio percorso formativo mi ha indirizzato verso quegli approcci che danno voce al corpo, alle sensazioni fisiche e al soma, come la psicoterapia sensomotoria e l'EMDR, perch&eacute; ho imparato prima di tutto sulla mia pelle che il corpo comunica a noi stessi e a chi ci &egrave; intorno molto prima che la nostra mente se ne accorga, e che solo imparando a rispettarlo e a comprendere i suoi messaggi si pu&ograve; vivere davvero appieno la propria salute, mentale e fisica, come un tutto integrato. Oggi nel mio lavoro mi occupo spesso di persone che vivono problematiche tipo ansia, depressione perfezionismo, paura del fallimento, senso di pressione: complessi emotivi che &egrave; necessario imparare a gestire e con i quali &egrave; bene fraternizzare al fine di vivere meglio le proprie passioni ed aumentare la qualit&agrave; della propria vita. Oggi insegno ai miei pazienti a conoscere e gestire la propria vita emotiva, cosicch&eacute; loro possano esprimere e godere pienamente della qualit&agrave; della propria vita.
            </p>
          </div>
        </div>
        <div className="service-block with-pic">
          <div>
            <h4>Perch&eacute; la scelta di una casa-studio</h4>
            <p>
              Siamo abituati ad immaginare lo studio dello psicologo dotato di lettino, così da poterci stendere su di esso avendo il terapeuta seduto dietro di noi, più anonimo possibile, impegnato a prendere appunti. Un tempo si pensava che lo psicoterapeuta dovesse essere totalmente impersonale, una figura quasi oscura. Oggi sappiamo che l'anonimato non è pi&ugrave; una caratteristica davvero utile, anzi addirittura le ricerche dicono che sia proprio lo psicoterapeuta come persona a fare la differenza nel trattamento. &Egrave; alla luce di queste nuove scoperte che ho deciso di utilizzare la casa-studio come setting per il mio lavoro: accogliere la persona in casa significa mettere me stesso nel setting come essere umano, come parte integrante del trattamento.
            </p>
            <p>
              Jacopo Biraschi
            </p>
          </div>
          <div className="pic-wrapper">
            <Img className="normal-size pic" fluid={data.studio.childImageSharp.fluid} loading="eager" />
          </div>
        </div>
        <div className="service-block">
          <PrenotaOra />
        </div>
      </article>
    </Layout>
  )
}

export default Servizi

export const query = graphql`
  query {
    photo13: file(relativePath: { eq: "absolutvision-82TpEld0_e4-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroPic: file(relativePath: { eq: "maglione_bianco.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    studio: file(relativePath: { eq: "studio.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
